
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap');
body {
  background-color: #fafafa !important;
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
    font-family: 'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app-body {
  background-color: red !important;
}
.react-datepicker-popper {  z-index: 100 !important }
.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    display: block;
    width: 100%;
    border-radius: 4px;
    border-color: #E8E8E8;
    padding: 3px;
}
