.App {
  text-align: center;
  background-color: #fafafa !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}
.mynav{
  z-index: 0;
  /* box-shadow: none; */
  
}
.custom-sidebar-logo{
  cursor: pointer;
}

.custom-sidebar-open
{
  width: 20%;
  min-height: 100%;
  z-index: 3; 
  position: fixed;
  background-color: #13538a;
  color:white;
  /* border-top-right-radius: 40px; */
  border-color: #ffff;
  border-width: 2px;
  
  /* box-shadow: none; */
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
}
.custom-sidebar-close
{
  width: 10%;
  min-height: 100%;
  z-index: 3;
  position: fixed;
  background-color: #13538a;
  color:white;
  /* border-top-right-radius: 40px; */
  border-color: #ffff;
  border-width: 2px;
  
  /* box-shadow: none; */
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
}
.content-open
{
width: 80%;
min-height: 100% !important;
background-color: white;
color: "#338673";
margin-left: 20%;

}
.content-close
{
width: 90%;
min-height: 100% !important;
background-color: white;
color: "#338673";
margin-left: 10%

}

.custom-sidebar-logo
{
  color: white;
  font-size: larger;
  width: 100%;
  text-align: center;
  padding: 10px 10px 10px 10px;
  
}
.custom-sidebar-menu-item
{

  padding: 10px 0px;
  color: white;
 
}

.custom-sidebar-menu-item-inner
{   
    padding: 10px 0px; 
    align-self: center;
   
    
}
.custom-sidebar-menu-item-inner-option{
  color: white;
 
  padding: 5px 0px;
}

.custom-sidebar-menu-item-inner:hover
{
  color: white !important;
  /* border-bottom: 4px solid white;
  border-top: 4px solid white; */
  
}
.webIcon{
 text-align:center;

 }
 .mobileIcon{
  text-align: left;
 }

.custom-sidebar-menu-item-inner-active
{
 
  color: white !important;
  background-color: #153a5b;
  /* border-bottom: 4px solid white;
  border-top: 4px solid white; */
 
}

.custom-sidebar-menu-item-inner-active a
{
  color: white !important;
}

.custom-sidebar-menu-item-inner:hover a
{
  color: white !important;
}

.custom-sidebar-menu-item-inner a 
{
  color: white;
}

.custom-sidebar-content-nav
{
  background-color: #fafafa;
  text-align: right;
}
.menu-icon{
  font-size: 2em;
}
.menu-text{
  display: flex;
  padding-top: 10px !important;
}
.logo{
  padding:auto;
}
.sidenav-align{
  display: block;
}
.sidenav-large{
  display: block;
}
.sidenav-small{
  display: none;
}
.sub-icon-open{
  text-align: end !important;

}
.sub-icon-close{
  text-align: center !important;
}
 
/* .logo-small{
  padding-right: 8px !important;

} */
.sub-text-open{
  text-align: start !important;
  justify-content: start !important;
}
.sub-text-close{
  text-align: center !important;
  justify-content: center;
}
.font-tag{
  font-size: 0.8rem !important;
}
.nav-item{
  display: none !important;
}

@media screen and (min-width: 760px) and (max-width: 1000px){
  .sub-icon-open{
    text-align: center !important;

  }
  .sub-icon-close{
    text-align: center !important;
  }
  .sub-text-open{
    text-align: center !important;
    justify-content: center !important;
  }
  .sub-text-close{
    text-align: center !important;
    justify-content: center !important;
  }
 
  

}

@media screen and (max-width: 540px) {
  .content-open{
    margin-left: 20%;
    width: 80%;
  }
  .content-close{
    margin-left: 20%;
    width: 80%;
   } 
  .small-text{
    font-size: 11px;
  }
  .custom-sidebar-close
  {
    width: 19%;
  }
  .custom-sidebar-open
  {
    width: 19%;
  }
  .menu-text{
    display: none !important;
    padding-top: 0px;
  }
  .sub-icon-open{
    text-align: center !important;
  }
  .font-tag{
    font-size: 0.7rem !important;
  }
  .sub-text-open{
    text-align: center !important;
    justify-content: center;
  }
  .sub-text-close{
    text-align: center !important;
    justify-content: center;
  }
  .sub-icon-close{
    text-align: center !important;
  }
  .logo-txt{
    display: none;
  }
  .submenu-icon{
    display: none;
  }
  .nav-btns{
    display: none !important;
  }
  .nav-item{
    display: block !important;
  }


.sidenav-large{
  display: none !important;
}
.sidenav-small{
  display: block !important;
}
}


@media screen and (max-width: 760px) {

  /* .content-close{
    margin-left: 20%;
    width: 80%;
   } */
   .sidebaritem{
     flex-direction: column;
   }
   .sidebaritem span{
     /* width: 70%; */
     margin-left: 0 !important;
   }
   .sub-text-open{
    text-align: center !important;
    justify-content: center !important;
  }
  .sub-text-close{
    text-align: center !important;
    justify-content: center !important;
  }
  
   .loginImage{
     display: none;
   }
   .custom-sidebar-logo{
     padding-left:0px ;
   }
  }
   
    
@media screen and (min-width: 540px) and (max-width: 760px) {
  /* 480 */
  /* 600 */
 
  .content-close{
   margin-left: 20%;
   width: 80%;
  }
  .sub-icon-open{
    text-align: center !important;

  }
  .sub-icon-close{
    text-align: center !important;
  }
 
  .sub-text-open{
    text-align: center !important;
    justify-content: center;
  }
  .sub-text-close{
    text-align: center !important;
    justify-content: center;
  }
  .sidebaritem{
    flex-direction: column;
  }
  .sidebaritem span{
    /* width: 70%; */
    margin-left: 0 !important;
  }
  
 
  .content-open{
    margin-left: 30%;
    width: 70%;
  }
  .custom-sidebar-close
  {
    width: 20%;
  }
  .custom-sidebar-open
  {
    width: 30%;
  }
  .loginImage{
    display: none;
  }
  .custom-sidebar-logo{
    padding-right: 23px;
  }
  
   
}